//
//
//
//
//

import Vue from 'vue'
import Element from 'element-ui'

Vue.use(Element)

const API_URL = 'https://geo.api.gouv.fr/communes?nom=:query&fields=nom,codesPostaux&boost=population&limit=5'
const ApiUrlCommune = 'https://geo.api.gouv.fr/communes?nom=:query&fields=centre,codesPostaux&boost=population&limit=1'
const ApiUrlAdresse = 'https://api-adresse.data.gouv.fr/search/?q=:query&type=housenumber&autocomplete=1'

export default {
  name: 'AutoCompleteGouvApi',
  props: {
    intitule: {
      type: String,
      default: 'Localisation'
    },
    autoCompType: {
      type: String,
      default: 'commune'
    },
    variant: String,
    ville: String,
    pays: String
  },
  computed: {
    formattedIntitule() {
      if (this.intitule === 'Localisation') {
        return this.intitule
      } else {
        return 'Votre ' + this.intitule
      }
    },
    formattedId() {
      return this.$options.filters.camelCase(this.intitule)
    }
  },
  data() {
    return {
      addresses: [],
      addressSearch: '',
      selectedAddress: null,
      maxMatches: 6,
      minMatches: 1,
      timeOut: null,
      strictMode: true
    }
  },

  methods: {
    async getAddresses(query) {
      let url = ''
      switch (this.autoCompType) {
        case 'commune': {
          url = API_URL
          break
        }
      }
      switch (this.autoCompType) {
        case 'pays': {
          const countryList = [
            'Afghanistan',
            'Iles Aland',
            'Albanie',
            'Algérie',
            'Samoa américaines',
            'Andorre',
            'Angola',
            'Anguilla',
            'Antarctique',
            'Antigua-et-Barbuda',
            'Argentine',
            'Arménie',
            'Aruba',
            'Australie',
            'L’Autriche',
            'Azerbaïdjan',
            'Bahamas (les)',
            'Bahrein',
            'Bangladesh',
            'Barbade',
            'Biélorussie',
            'Belgique',
            'Belize',
            'Bénin',
            'Bermudes',
            'Bhoutan',
            'Bolivie (État plurinational de)',
            'Bonaire, Saint-Eustache et Saba',
            'Bosnie Herzégovine',
            'Botswana',
            'Île Bouvet',
            'Brésil',
            'Territoire britannique de l’océan Indien (le)',
            'Brunei Darussalam',
            'Bulgarie',
            'Burkina Faso',
            'Burundi',
            'Cabo Verde',
            'Cambodge',
            'Cameroun',
            'Canada',
            'Îles Caïmans (les)',
            'République centrafricaine (la)',
            'Tchad',
            'Chili',
            'Chine',
            'L’île de noël',
            'Îles Cocos (Keeling) (les)',
            'Colombie',
            'Comores (les)',
            'Congo (République démocratique du)',
            'Congo (le)',
            'Îles Cook (les)',
            'Costa Rica',
            'Croatie',
            'Cuba',
            'Curacao',
            'Chypre',
            'Tchéquie',
            'Côte d’Ivoire',
            'Danemark',
            'Djibouti',
            'Dominique',
            'République dominicaine (la)',
            'Équateur',
            'Egypte',
            'Le Salvador',
            'Guinée Équatoriale',
            'Érythrée',
            'Estonie',
            'Eswatini',
            'Ethiopie',
            'Îles Falkland (les) [Malvinas]',
            'Îles Féroé (les)',
            'Fidji',
            'Finlande',
            'France',
            'Guyane Française',
            'Polynésie française',
            'Territoires français du Sud (les)',
            'Gabon',
            'Gambie (la)',
            'Géorgie',
            'Allemagne',
            'Ghana',
            'Gibraltar',
            'Grèce',
            'Groenland',
            'Grenade',
            'Guadeloupe',
            'Guam',
            'Guatemala',
            'Guernesey',
            'Guinée',
            'Guinée-Bissau',
            'Guyane',
            'Haïti',
            'Îles Heard et McDonald',
            'Saint-Siège (le)',
            'Honduras',
            'Hong Kong',
            'Hongrie',
            'Islande',
            'Inde',
            'Indonésie',
            'Iran (République islamique d ‘)',
            'Irak',
            'Irlande',
            'île de Man',
            'Israël',
            'Italie',
            'Jamaïque',
            'Japon',
            'Jersey',
            'Jordan',
            'Kazakhstan',
            'Kenya',
            'Kiribati',
            'Corée (République populaire démocratique de)',
            'Corée (République de)',
            'Koweit',
            'Kirghizistan',
            'République démocratique populaire lao (la)',
            'Lettonie',
            'Liban',
            'Lesotho',
            'Libéria',
            'Libye',
            'Liechtenstein',
            'Lituanie',
            'Luxembourg',
            'Macao',
            'Madagascar',
            'Malawi',
            'Malaisie',
            'Maldives',
            'Mali',
            'Malte',
            'Îles Marshall (les)',
            'Martinique',
            'Mauritanie',
            'Maurice',
            'Mayotte',
            'Mexique',
            'Micronésie (États fédérés de)',
            'Moldova (République de)',
            'Monaco',
            'Mongolie',
            'Monténégro',
            'Montserrat',
            'Maroc',
            'Mozambique',
            'Myanmar',
            'Namibie',
            'Nauru',
            'Népal',
            'Pays-Bas (les)',
            'Nouvelle Calédonie',
            'Nouvelle-Zélande',
            'Nicaragua',
            'Niger (le)',
            'Nigeria',
            'Niue',
            'l’ile de Norfolk',
            'Îles Mariannes du Nord (les)',
            'Norvège',
            'Oman',
            'Pakistan',
            'Palau',
            'Palestine, État de',
            'Panama',
            'Papouasie Nouvelle Guinée',
            'Paraguay',
            'Pérou',
            'Philippines (les)',
            'Pitcairn',
            'Pologne',
            'le Portugal',
            'Porto Rico',
            'Qatar',
            'République de Macédoine du Nord',
            'Roumanie',
            'Fédération de Russie (la)',
            'Rwanda',
            'Réunion',
            'Saint Barthélemy',
            'Sainte-Hélène, Ascension et Tristan da Cunha',
            'Saint-Christophe-et-Niévès',
            'Sainte-Lucie',
            'Saint Martin (partie française)',
            'Saint-Pierre-et-Miquelon',
            'Saint-Vincent-et-les-Grenadines',
            'Samoa',
            'Saint Marin',
            'Sao Tomé et Principe',
            'Arabie Saoudite',
            'Sénégal',
            'Serbie',
            'les Seychelles',
            'Sierra Leone',
            'Singapour',
            'Sint Maarten (partie néerlandaise)',
            'Slovaquie',
            'Slovénie',
            'Les îles Salomon',
            'Somalie',
            'Afrique du Sud',
            'Géorgie du Sud et les îles Sandwich du Sud',
            'Soudan du sud',
            'Espagne',
            'Sri Lanka',
            'Soudan (le)',
            'Suriname',
            'Svalbard et Jan Mayen',
            'Suède',
            'Suisse',
            'République arabe syrienne',
            'Taiwan, Province de Chine)',
            'Tadjikistan',
            'Tanzanie, République-Unie de',
            'Thaïlande',
            'Timor-Leste',
            'Aller',
            'Tokelau',
            'Tonga',
            'Trinité-et-Tobago',
            'Tunisie',
            'dinde',
            'Turkménistan',
            'Îles Turques et Caïques (les)',
            'Tuvalu',
            'Ouganda',
            'Ukraine',
            'Emirats Arabes Unis (les)',
            'Royaume-Uni de Grande-Bretagne et d’Irlande du Nord (le)',
            'Îles Mineures Éloignées des États-Unis (les)',
            'États-Unis d’Amérique (les)',
            'Uruguay',
            'Ouzbékistan',
            'Vanuatu',
            'Venezuela (République bolivarienne du)',
            'Viet Nam',
            'Îles Vierges (britanniques)',
            'Îles Vierges (États-Unis)',
            'Wallis et Futuna',
            'Sahara occidental',
            'Yémen',
            'Zambie',
            'Zimbabwe'
          ]
          this.addresses = query ? countryList.filter(country => {
            return (country.toLowerCase().indexOf(query.toLowerCase()) === 0)
          }) : countryList
          break
        }
        case 'commune': {
          fetch(url.replace(':query', query))
            .then((response) => {
              response.json()
                .then((result) => {
                  const arrayAdresses = []
                  const geoCodeAdresses = result
                  Object.entries(geoCodeAdresses).forEach(([index, value]) => {
                    arrayAdresses.push(value.nom)
                  })
                  this.addresses = arrayAdresses
                })
            })
          break
        }
      }
      return this.addresses
    },
    async handleSelect($event) {
      switch (this.autoCompType) {
        case 'pays': {
          const toSend = {
            pays: $event
          }
          this.$emit('changedGPSData', toSend)
          break
        }
        case 'commune': {
          const toSend = {
            commune: $event
          }
          this.$emit('changedGPSData', toSend)
          break
        }
      }
    },
    async getSuggestion(queryStr, cb) {
      this.getAddresses(queryStr)
        .then(suggestions => {
          let results = suggestions.map(result => {
            return { 'value': result }
          })
          cb(results);
        })
    }
  }
}
